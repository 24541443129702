import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"

import { pagesLinks } from "../../common/links"

export default [
	{
		title: "Облачная программа автоматизации",
		description: (
			<>
				<p>
					Управляй рестораном с любого устройства с доступом в&nbsp;интернет.
					Все документы надёжно хранятся в&nbsp;«облаке» — акты и накладные,
					данные о продажах за&nbsp;месяцы и годы, сведения о поставщиках и
					версии техкарт.
				</p>
				<p>
					Нет времени делать всё самому — делегируй сотрудникам. Работник
					на кассе вносит приходные накладные и делает заявки на закупки.
					Данные автоматически загружаются в&nbsp;программу управления рестораном.
					Руководителю остаётся проверить документы в&nbsp;бэк-офисе и&nbsp;провести.
				</p>
			</>
		),

		img: (
			<StaticImage
				className={styles.image1}
				src="./assets/restaurant-feature-1.png"
				alt="система автоматизации ресторана"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: styles.feature1,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.reports.href,
		backdrop: <div className={styles.featureTopRightTrapezeBackdrop} />,
	},
	{
		title: "Удобная CRM система управления рестораном",
		description: (
			<div className={styles.smallDescription}>
				<p>
					Quick Resto поддержит программы лояльности, чтобы радовать гостей
					скидками и начислять бонусы для возвращения посетителей.
				</p>
				<p>
					Софт для ресторана также включает приложение для гостей, с помощью
					которого можно предложить предзаказ и автоматизировать доставку. Можно
					работать с собственными курьерами или агрегаторами — решай, что
					выгоднее твоему заведению.
				</p>
				<p>
					В CRM системе для ресторана хранятся все данные о клиентах: контакты,
					накопленные бонусы, чеки.
				</p>
			</div>
		),

		img: (
			<StaticImage
				className={styles.image2}
				src="./assets/restaurant-feature-2.png"
				alt="crm для ресторана"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: styles.feature2,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.marketing.href,
	},
	{
		title: "Аналитика и отчёты",
		description: (
			<>
				<p>
					Анализируй продажи с разных сторон: система автоматизации ресторанного
					бизнеса сформирует отчёты по&nbsp;часам, по среднему чеку, по блюдам,
					по сотрудникам, по&nbsp;гостям.
				</p>
				<p>
					Собирай данные по&nbsp;заказам ресторана в&nbsp;программе и&nbsp;стой наглядные отчёты с&nbsp;графиками.
					Оцени, в какое время приходят гости, что они любят заказывать
					и сколько тратят денег в заведении. Выявляй акции, которые принесли
					максимум прибыли.
				</p>
				<p>
					Используй АВС-анализ и выделяй блюда, которые нужно активнее
					рекламировать, дорабатывать или вывести из ассортимента. Сделай
					релевантное меню, которое будет приносить предприятию питания больше
					прибыли.
				</p>
			</>
		),

		img: (
			<StaticImage
				className={styles.image3}
				src="./assets/restaurant-feature-3.png"
				alt="программа управления рестораном"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		reverse: true,

		className: styles.feature3,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.reports.href,
		backdrop: <div className={styles.featureTopLeftTrapezeBackdrop} />,
	},
	{
		title: "Складской модуль",
		description: (
			<>
				<p>
					Quick Resto справится с любым оборотом продуктов на складе, даже если
					у заведения несколько точек хранения. Автоматизация упростит закупки и
					подсчёт товаров: наглядно видны все недостачи и излишки. Привезли продукты
					в&nbsp;нестандартной фасовке&nbsp;&mdash; программа учёта ресторана переведёт
					их&nbsp;в&nbsp;нужные единицы измерения.
				</p>
				<p>
					Если не успеваешь внести приходные накладные — сделай это позже:
					система управления рестораном правильно пересчитает остатки. При
					закупке продуктов Quick Resto проинформирует, если цена поставщика
					выше, чем в прошлый раз.
				</p>
			</>
		),

		img: (
			<StaticImage
				className={styles.image4}
				src="./assets/restaurant-feature-4.png"
				alt="программа учета для ресторана"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: styles.feature4,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.warehouse.href,
		backdrop: <div className={styles.featureTopRightTrapezeBackdrop} />,
	},
	{
		title: "Table Service для ресторанов",
		description: (
			<>
				<p>
					Система автоматизации ресторана и кафе помогает сделать обслуживание
					более внимательным. Благодаря схеме столов официант легко
					ориентируется в заказах.
				</p>
				<p>
					Чтобы ускорить процесс приготовления блюд, установи на кухне экран для
					повара. Программа автоматизирует заказы в ресторане. Как только
					официант принимает заказ, повар тут же видит его на своём терминале
					с разбивкой по цехам или блюдам. Когда повар отметит готовность,
					на терминале официанта изменится статус и придёт звуковое
					уведомление — больше никаких задержек.
				</p>
				<p>
					С комментариями к заказу на кухне не забудут добавить фирменный соус
					или уменьшить количество соли. Предоставь гостям лучший сервис с Quick
					Resto.
				</p>
			</>
		),

		img: (
			<StaticImage
				className={styles.image5}
				src="./assets/restaurant-feature-5.png"
				alt="технологические карты блюд"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: styles.feature5,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.menu.href,
	},
	{
		title: "Расчёт себестоимости и калькуляция",
		description: (
			<>
				<p>
					В программе управления рестораном есть инструменты для калькуляции
					и контроля производства — техкарты, учёт потерь при обработке, нормы
					закладки. Легко следить за фудкостом и искать пути для повышения
					маржинальности блюд.
				</p>
				<p>
					Quick Resto автоматически посчитает себестоимость, вес и КБЖУ блюд.
					Программа автоматизации ресторана позволяет избавиться от&nbsp;ошибок
					в&nbsp;расчётах, которые часто допускают люди.
				</p>
			</>
		),

		img: (
			<StaticImage
				className={styles.image6}
				src="./assets/restaurant-feature-6.png"
				alt="quick resto manager"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: styles.feature6,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.indexQRM.href,
		backdrop: <div className={styles.featureTopLeftTrapezeBackdrop} />,
	},
	{
		title: "Комплексный учет и управление рестораном из любой точки",
		description: (
			<p className={styles.appDescription}>
				С автоматизацией ресторанного бизнеса не нужно быть в заведении 24/7, чтобы
				управлять бизнесом. Quick Resto оповестит о низких остатках на складе,
				сформирует отчёты по продажам и возьмёт на себя ряд других задач. А в
				приложении для руководителя можно проверить выручку и проанализировать
				спрос на блюда среди гостей и работу официантов.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image7}
				src="./assets/restaurant-feature-7.png"
				alt="кухонный экран повара"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: styles.feature7,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.indexKDS.href,
		backdrop: <div className={styles.featureTopRightTrapezeBackdrop} />,
	},
	{
		title: "Внедрение системы автоматизации под ключ",
		description: (
			<p>
				Мы понимаем, что у руководителя много дел перед открытием, поэтому
				автоматизация деятельности ресторана может стать нашей задачей. Заполним
				техкарты, зарегистрируем кассу в ФНС и подключим ЕГАИС. Подскажем, как
				настроить систему для дополнительных продаж и защиты от махинаций
				персонала. Поможем с выбором POS-оборудования.
				<br />
				Не нужно тратить время и настраивать систему самому — достаточно купить
				программу для ресторана Quick Resto.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image8}
				src="./assets/restaurant-feature-8.png"
				alt="Алексей Гаврилов"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: styles.feature8,
		theme: "primary",
		backdrop: <div className={styles.feature8Backdrop} />,
	},
]
